import React from 'react'
import { Headline } from './Headline'
import { ManCopy } from './ManCopy'
import { ManButton } from './ManButton'
import { useI18next } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { structuredTracking } from '../service/tracking'

const Header = styled.header`
  margin-bottom: 24px;
`

const Footer = styled.footer`
  margin-top: 50px;
`

export const SuccessView = ({ onReset, sendingDate }) => {
  const { t } = useI18next()

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-8'>
          <Header>
            <Headline size='xxl' as='h1'>
              <span
                dangerouslySetInnerHTML={{ __html: t('successView.headline') }}
              />
            </Headline>
          </Header>
          <ManCopy style={{ fontSize: '18px' }}>
            {t('successView.copy', { date: sendingDate })}
          </ManCopy>
          <Footer>
            <div style={{ display: 'flex' }}>
              <ManButton
                width='auto'
                onClick={() => {
                  onReset()

                  structuredTracking({
                    eventCategory: 'Website Modules',
                    eventAction: 'Button Clicks - Primary',
                    eventLabel: t('successView.cta'),
                  })
                }}
              >
                {t('successView.cta')}
              </ManButton>
            </div>
          </Footer>

          <div style={{ height: 110 }} />
        </div>
      </div>
    </div>
  )
}
